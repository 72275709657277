import './link.css'

export default ({url, text}: {
    url: string;
    text: string|React.ReactNode;
}) => (
    <div className="link">
        <a href={url} className="link__title" target={'_blank'}>
            {text}
        </a>
    </div>

)