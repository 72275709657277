import router from "./routes";

export interface PortfolioData {
    id: number;
    name: string;
    description: string;
    url: string;
    img: string;
}

export default async (): Promise<PortfolioData[]> => {
    const response = await fetch(router['portfolio']);
    return (await response.json()).rows;
}